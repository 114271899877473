export const borderWidths = {
  px: `1px`,
  0: `0`,
  2: `2px`,
  4: `4px`,
  8: `8px`,
}

export const breakpoints = [`640px`, `758px`, `1024px`, `1280px`]

const commonButtonStyles = {
  py: 2,
  px: 3,
  cursor: `pointer`,
  fontSize: `100%`,
  lineHeight: `inherit`,
}

export const buttons = {
  simple: {
    ...commonButtonStyles,
    backgroundColor: `primary`,
    border: `none`,
    color: `white`,
    fontWeight: `bold`,
    borderRadius: `default`,
    "&:hover": {
      backgroundColor: `primaryHover`,
    },
  },
  pill: {
    ...commonButtonStyles,
    backgroundColor: `primary`,
    border: `none`,
    color: `white`,
    fontWeight: `bold`,
    borderRadius: `full`,
    "&:hover": {
      backgroundColor: `primaryHover`,
    },
  },
  outline: {
    ...commonButtonStyles,
    backgroundColor: `transparent`,
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: `primary`,
    color: `primary`,
    fontWeight: `semibold`,
    borderRadius: `default`,
    "&:hover": {
      backgroundColor: `primary`,
      color: `white`,
      borderColor: `transparent`,
    },
  },
  bordered: {
    ...commonButtonStyles,
    backgroundColor: `primary`,
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: `primaryHover`,
    color: `white`,
    fontWeight: `bold`,
    borderRadius: `default`,
    "&:hover": {
      backgroundColor: `primaryHover`,
    },
  },
  disabled: {
    ...commonButtonStyles,
    backgroundColor: `primary`,
    border: `none`,
    opacity: 0.5,
    cursor: `not-allowed`,
    color: `white`,
    fontWeight: `bold`,
    borderRadius: `default`,
  },
  "3D": {
    ...commonButtonStyles,
    backgroundColor: `primary`,
    border: `none`,
    borderBottomWidth: `4px`,
    borderBottomStyle: `solid`,
    borderBottomColor: `primaryHover`,
    color: `white`,
    fontWeight: `bold`,
    borderRadius: `default`,
    transition: `transform 0.3s ease-in-out`,
    "&:hover": {
      transform: `translateY(-1px)`,
    },
  },
  elevated: {
    ...commonButtonStyles,
    backgroundColor: `white`,
    borderWidth: `1px`,
    borderStyle: `solid`,
    borderColor: `gray.4`,
    color: `text`,
    fontWeight: `bold`,
    borderRadius: `default`,
    boxShadow: `default`,
    "&:hover": {
      backgroundColor: `gray.1`,
    },
  },
}

export const components = {
  components: {
    blockquote: {
      background: `#FDEBC9`,
      text: `#1A202C`,
    }
  }
}

export const baseColors = {
  transparent: `transparent`,
  black: `#000`,
  white: `#fff`,
  gray: [null, `var(--gray-100)`, `var(--gray-200)`, `var(--gray-300)`, `var(--gray-400)`, `var(--gray-500)`, `var(--gray-600)`, `var(--gray-700)`, `var(--gray-800)`, `var(--gray-900)`],
  red: [null, `var(--red-100)`, `var(--red-200)`, `var(--red-300)`, `var(--red-400)`, `var(--red-500)`, `var(--red-600)`, `var(--red-700)`, `var(--red-800)`, `var(--red-900)`],
  orange: [null, `var(--orange-100)`, `var(--orange-200)`, `var(--orange-300)`, `var(--orange-400)`, `var(--orange-500)`, `var(--orange-600)`, `var(--orange-700)`, `var(--orange-800)`, `var(--orange-900)`],
  yellow: [null, `var(--yellow-100)`, `var(--yellow-200)`, `var(--yellow-300)`, `var(--yellow-400)`, `var(--yellow-500)`, `var(--yellow-600)`, `var(--yellow-700)`, `var(--yellow-800)`, `var(--yellow-900)`],
  green: [null, `var(--green-100)`, `var(--green-200)`, `var(--green-300)`, `var(--green-400)`, `var(--green-500)`, `var(--green-600)`, `var(--green-700)`, `var(--green-800)`, `var(--green-900)`],
  teal: [null, `var(--teal-100)`, `var(--teal-200)`, `var(--teal-300)`, `var(--teal-400)`, `var(--teal-500)`, `var(--teal-600)`, `var(--teal-700)`, `var(--teal-800)`, `var(--teal-900)`],
  blue: [null, `var(--blue-100)`, `var(--blue-200)`, `var(--blue-300)`, `var(--blue-400)`, `var(--blue-500)`, `var(--blue-600)`, `var(--blue-700)`, `var(--blue-800)`, `var(--blue-900)`],
  indigo: [null, `var(--indigo-100)`, `var(--indigo-200)`, `var(--indigo-300)`, `var(--indigo-400)`, `var(--indigo-500)`, `var(--indigo-600)`, `var(--indigo-700)`, `var(--indigo-800)`, `var(--indigo-900)`],
  purple: [null, `var(--purple-100)`, `var(--purple-200)`, `var(--purple-300)`, `var(--purple-400)`, `var(--purple-500)`, `var(--purple-600)`, `var(--purple-700)`, `var(--purple-800)`, `var(--purple-900)`],
  pink: [null, `var(--pink-100)`, `var(--pink-200)`, `var(--pink-300)`, `var(--pink-400)`, `var(--pink-500)`, `var(--pink-600)`, `var(--pink-700)`, `var(--pink-800)`, `var(--pink-900)`],
}

export const colors = {
  ...baseColors,
  ...components,
  grayDark: baseColors.gray[8],
  text: baseColors.gray[8],
  background: baseColors.white,
  primary: baseColors.blue[7],
  primaryHover: baseColors.blue[8],
  secondary: baseColors.gray[6],
  muted: baseColors.gray[3],
  success: baseColors.green[3],
  info: baseColors.blue[4],
  warning: baseColors.yellow[3],
  danger: baseColors.red[3],
  light: baseColors.gray[1],
  dark: baseColors.gray[8],
  textMuted: baseColors.gray[6],
  shape: baseColors.gray[1],
  title: baseColors.gray[7],
  border: baseColors.gray[4]
}

export const baseFonts = {
  rounded: `'Nunito', sans-serif;`,
  sans: `'Nunito Sans', sans-serif;`,
  system: `-apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
}

export const fonts = {
  ...baseFonts,
  heading: `-apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
  body: `-apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`
}

export const fontSizes = [`0.8rem`, `1rem`, `1.25rem`, `1.5rem`, `1.75rem`, `2rem`, `2.5rem`]

export const baseFontWeights = {
  thin: `200`,
  light: `300`,
  normal: `400`,
  semibold: `600`,
  bold: `700`,
  extrabold: `800`,
}

export const fontWeights = {
  ...baseFontWeights,
}

const commonInputStyles = {
  py: 2,
  px: 3,
  fontSize: `100%`,
  borderRadius: `default`,
  appearance: `none`,
  lineHeight: `tight`,
}

export const inputs = {
  shadow: {
    ...commonInputStyles,
    border: `none`,
    color: `gray.7`,
    boxShadow: `default`,
    "&:focus": {
      outline: `none`,
      boxShadow: `outline`,
    },
  },
  inline: {
    ...commonInputStyles,
    backgroundColor: `gray.2`,
    borderWidth: `2px`,
    borderStyle: `solid`,
    borderColor: `gray.2`,
    color: `gray.7`,
    "&:focus": {
      outline: `none`,
      borderColor: `primary`,
      backgroundColor: `white`,
    },
  },
  underline: {
    ...commonInputStyles,
    backgroundColor: `transparent`,
    border: `none`,
    borderBottomWidth: `2px`,
    borderBottomStyle: `solid`,
    borderBottomColor: `primary`,
    borderRadius: `0px`,
    color: `gray.7`,
    "&:focus": {
      outline: `none`,
      borderColor: `primary`,
      backgroundColor: `white`,
    },
  },
}

export const letterSpacings = {
  tighter: `-0.05em`,
  tight: `-0.025em`,
  normal: `0`,
  wide: `0.025em`,
  wider: `0.05em`,
  widest: `0.1em`,
}

export const baseLineHeights = {
  none: `1`,
  tight: `1.25`,
  snug: `1.375`,
  normal: `1.5`,
  relaxed: `1.625`,
  loose: `2`,
}

export const lineHeights = {
  ...baseLineHeights,
  body: baseLineHeights.relaxed,
  heading: baseLineHeights.tight,
}

export const radii = {
  none: `0`,
  sm: `0.125rem`,
  default: `0.25rem`,
  lg: `0.5rem`,
  full: `9999px`,
}

export const sizes = {
  px: `1px`,
  0: `0`,
  1: `0.25rem`,
  2: `0.5rem`,
  3: `0.75rem`,
  4: `1rem`,
  5: `1.25rem`,
  6: `1.5rem`,
  8: `2rem`,
  10: `2.5rem`,
  12: `3rem`,
  16: `4rem`,
  20: `5rem`,
  24: `6rem`,
  32: `8rem`,
  40: `10rem`,
  48: `12rem`,
  56: `14rem`,
  64: `16rem`,
  xs: `20rem`,
  sm: `24rem`,
  md: `28rem`,
  lg: `32rem`,
  xl: `36rem`,
  "2xl": `42rem`,
  "3xl": `48rem`,
  "4xl": `56rem`,
  "5xl": `64rem`,
  "6xl": `72rem`,
  "1/2": `50%`,
  "1/3": `33.333333%`,
  "2/3": `66.666667%`,
  "1/4": `25%`,
  "2/4": `50%`,
  "3/4": `75%`,
  "1/5": `20%`,
  "2/5": `40%`,
  "3/5": `60%`,
  "4/5": `80%`,
  "1/6": `16.666667%`,
  "2/6": `33.333333%`,
  "3/6": `50%`,
  "4/6": `66.666667%`,
  "5/6": `83.333333%`,
  "1/12": `8.333333%`,
  "2/12": `16.666667%`,
  "3/12": `25%`,
  "4/12": `33.333333%`,
  "5/12": `41.666667%`,
  "6/12": `50%`,
  "7/12": `58.333333%`,
  "8/12": `66.666667%`,
  "9/12": `75%`,
  "10/12": `83.333333%`,
  "11/12": `91.666667%`,
  full: `100%`,
  screenHeight: `100vh`,
  screenWidth: `100vw`,
}

export const shadows = {
  default: `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
  md: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  lg: `0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
  xl: `0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)`,
  "2xl": `0 25px 50px -12px rgba(0, 0, 0, 0.25)`,
  inner: `inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)`,
  outline: `0 0 0 3px rgba(66, 153, 225, 0.5)`,
  none: `none`,
}

export const space = [0, `0.25rem`, `0.5rem`, `1rem`, `2rem`, `4rem`, `8rem`, `16rem`, `32rem`]

export const zIndices = {
  auto: `auto`,
  0: `0`,
  10: `10`,
  20: `20`,
  30: `30`,
  40: `40`,
  50: `50`,
}

const heading = {
  fontFamily: `heading`,
  fontWeight: `heading`,
  lineHeight: `heading`,
  m: 0,
  mb: 1,
}

export const styles = {
  root: {
    fontFamily: `body`,
    lineHeight: `body`,
    fontWeight: `body`,
  },
  a: {
    color: `primary`,
    textDecoration: `none`,
    ":hover": {
      textDecoration: `underline`,
    },
  },
  h1: {
    ...heading,
    fontSize: 5,
    mt: 2,
    mb: 3,
  },
  h2: {
    ...heading,
    fontSize: 3,
    mt: 2,
    mb: 2,
  },
  h3: {
    ...heading,
    fontSize: 1,
    mt: 2,
    mb: 2,
  },
  h4: {
    ...heading,
    fontSize: 1,
  },
  h5: {
    ...heading,
    fontSize: 1,
  },
  h6: {
    ...heading,
    fontSize: 1,
    mb: 2,
  },
  code: {},
  pre: {},
  hr: {
    bg: `muted`,
    border: 0,
    height: `1px`,
    m: 3,
  },
}

export const theme = {
  borderWidths,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  sizes,
  shadows,
  space,
  radii,
  zIndices,
  styles,
  buttons,
  inputs,
}

export default theme
