import AlertSm from "../../../../src/components/Bootstrap/Alert";
import StyleWrapper from "../../../../src/components/Bootstrap/Style";
import { IonIcon } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import * as React from 'react';
export default {
  AlertSm,
  StyleWrapper,
  IonIcon,
  informationCircle,
  React
};