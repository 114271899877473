import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const IconLoader = () => {
  // The path to this folder is set in the gatsby-config.js file
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "data" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
    `)
    return (
      <table>
      <thead>
      <tr class="css-0">
      <th class="css-0">#</th>
      <th class="css-0">Icon</th>
      <th class="css-0">App Name</th>
      <th class="css-0">File <small>(Click to download)</small></th>
      </tr>
      </thead>
      <tbody>
      {data.allFile.edges.map((file, index) => {

        // Finds module name, removes the *-icon icon text and removes the dashes
        let moduleName = file.node.name;
        moduleName = moduleName.substring(0, moduleName.length - 5);
        moduleName = moduleName.replace(/-/g, " ");

        // Starts the app count from 1 so we can see how many apps are available
        let appNumber = (index + 1);


        return (
          <tr class="css-0">
          <td class="css-0">{appNumber}</td>
          <td class="css-0"><img width="40" src={file.node.publicURL} alt={moduleName + " icon"}/></td>
          <td class="css-0"><span style={{textTransform: "capitalize"}}>{moduleName}</span></td>
          <td class="css-0"><a href={file.node.publicURL} download>{file.node.name + '.svg'}</a></td>
          </tr>
        )
      })}
      </tbody>
      </table>
    )
  }
  export default IconLoader
