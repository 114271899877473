import React, { useEffect } from "react";
import Alert from 'react-bootstrap/Alert';

import '@claromentis/design-system';
import { defineCustomElements } from '@claromentis/design-system/dist/loader';


export default function AlertSm({context, children}) {

    useEffect(() => {
        // This is to ensure the components are defined
        defineCustomElements(window);
    }, []);

    return (
        <div>
            <style type="text/css">
            {`
                .alert-sm {
                    padding: .4rem .6rem;
                    border-radius: 30px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                }
                
                .alert-sm > * + *:last-child {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .alert.alert-primary{color:var(--theme-primary-color-contrast,#2e517f);border-color:var(--theme-primary-color,#ebf8ff);background-color:var(--theme-primary-color,#ebf8ff)}
                .alert-sm.alert-primary{border:2px solid var(--theme-primary-color-variant,#91cdf3)}
                .alert.alert-secondary{color:#4c5567;border-color:#edf2f7;background-color:#edf2f7}
                .alert-sm.alert-secondary{border:2px solid #cdd6e0}
                .alert.alert-danger{color:#732b2b;border-color:#fff5f5;background-color:#fff5f5}
                .alert-sm.alert-danger{border:2px solid #fdb4b4}
                .alert.alert-success{color:#286749;border-color:#f0fff4;background-color:#f0fff4}
                .alert-sm.alert-success{border:2px solid #9ae5b3}
                .alert.alert-warning{color:#965b17;border-color:ivory;background-color:ivory}
                .alert-sm.alert-warning{border:2px solid #f8ef72}
                .alert.alert-info{color:#2e517f;border-color:#ebf8ff;background-color:#ebf8ff}
                .alert-sm.alert-info{border:2px solid #91cdf3}
                .alert.alert-light{color:#1a212d;border-color:#f8fafc;background-color:#f8fafc}
                .alert-sm.alert-light{border:2px solid #e1e7ef}
                .alert.alert-dark{color:#fff;border-color:#707989;background-color:#707989}.alert-sm.alert-dark{border:2px solid #2e3747}
            `}
            </style>

            <Alert variant={context + ` alert-sm`}>
                {children}
            </Alert>
            {/*<Alert variant="secondary alert-sm">*/}
            {/*    hellooo*/}
            {/*</Alert>*/}
            {/*<Alert variant="danger alert-sm">*/}
            {/*    hellooo*/}
            {/*</Alert>*/}
            {/*<Alert variant="success alert-sm">*/}
            {/*    hellooo*/}
            {/*</Alert>*/}
            {/*<Alert variant="warning alert-sm">*/}
            {/*    <cla-badge style={{marginRight: 0.5 + 'rem'}} styleclass="badge badge-pill badge-warning">*/}
            {/*        <IonIcon icon={informationCircle}></IonIcon>*/}
            {/*        Safe mode*/}
            {/*    </cla-badge>*/}
            {/*    Component content is saved but not displayed in order to assist with troubleshooting*/}
            {/*</Alert>*/}
            {/*<Alert variant="info alert-sm">*/}
            {/*    hellooo*/}
            {/*</Alert>*/}
            {/*<Alert variant="light alert-sm">*/}
            {/*    hellooo*/}
            {/*</Alert>*/}
            {/*<Alert variant="dark alert-sm">*/}
            {/*    hellooo*/}
            {/*</Alert>*/}
        </div>
    );
}